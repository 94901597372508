import { CategoryData } from '../../features/categories';
import apiClient from '../utils/api-client';

export async function getCategories() {
  try {
    const response = await apiClient.get<CategoryData[]>(
      '/api.php?method=get-categories',
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
