import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { InputMask } from '../../../app/components';
import { InputError } from '../../../app/components/text-field.styled';
import { Paper } from '../screens/basket-screen.styled';
import { BasketForm, BasketFormFields } from '../types';
import {
  BasketShopFormLabel,
  BasketShopFormInput,
} from './basket-shop-form.styled';

export interface BasketShopFormProps {
  register: UseFormRegister<BasketForm>;
  errors: Partial<FieldErrorsImpl<BasketForm>>;
}

function BasketShopForm({ register, errors }: BasketShopFormProps) {
  return (
    <Paper>
      <BasketShopFormLabel>Особиста інформація</BasketShopFormLabel>
      <BasketShopFormInput
        error={!!errors[BasketFormFields.NAME]}
        placeholder="І’мя"
        {...register(BasketFormFields.NAME)}
        message={errors[BasketFormFields.NAME]?.message}
      />
      <InputMask
        mask="+999 (99) 999 99 99"
        maskChar="X"
        {...register(BasketFormFields.PHONE)}
      />

      {!!errors[BasketFormFields.PHONE] && (
        <InputError>{errors[BasketFormFields.PHONE]?.message}</InputError>
      )}
      <BasketShopFormInput
        error={!!errors[BasketFormFields.EMAIL]}
        placeholder="Електронна пошта"
        {...register(BasketFormFields.EMAIL)}
        message={errors[BasketFormFields.EMAIL]?.message}
      />
    </Paper>
  );
}

export default BasketShopForm;
