import { combineReducers } from '@reduxjs/toolkit';
import categories from '../../features/categories/slice';
import products from '../../features/products/slice';
import product from '../../features/product/slice';
import basket from '../../features/basket/slice';
import profile from '../../features/profile/slice';

import asyncRequestStateReducer, {
  REDUCER_NAME as ASYNC_REQUEST_STATE_REDUCER_NAME,
} from './async-request-state-reducer';
import { basketApi } from '../../features/basket/api/basket.api';

const appReducer = combineReducers({
  profile,
  categories,
  products,
  product,
  basket,
  [basketApi.reducerPath]: basketApi.reducer,
  [ASYNC_REQUEST_STATE_REDUCER_NAME]: asyncRequestStateReducer,
});

export default appReducer;
