import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { getChatId, getProductsFromBasket } from '../selectors';
import {
  Root,
  Title,
  List,
  Item,
  Paper,
  AccordionItemButtonStyled,
  AccordionItemHeadingStyled,
  AccordionItemPanelStyled,
  AccordionItemStyled,
  AccordionStyled,
  PlusIcon,
  Label,
  RadioInputWrapper,
  RadioInput,
  RadioIcon,
  RadioText,
  Cell,
  AddressTime,
  Row,
  RadioButtonWrapper,
  RadioInputButton,
  RadioButton,
  // ButtonValue,
  // ButtonCount,
  // Button,
  Wallet,
  Card,
} from './basket-screen.styled';

import {
  BasketForm,
  BasketFormFields,
  DeliveryTypes,
  PayTypes,
} from '../types';
import { TextArea } from '../../../app/components';
import { useGetShopsQuery } from '../api/basket.api';
import { sendOrder } from '../../../app/services/order-service';
import { orderScheme } from '../scheme';
import { resetBasketData, updateToCurrentAmount } from '../slice';
import { history } from '../../../app';
import { getDeniedPath, getSuccessPath } from '../../../app/app-router-paths';
import { getProfileData } from '../../profile/selectors';
import { BasketShopForm } from '../components';

export default function BasketScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { data: addresses = [] } = useGetShopsQuery();
  const basketProducts = useAppSelector(getProductsFromBasket);
  const {
    currency = 'грн.',
    delivery_methods,
    payment_methods,
    user,
  } = useAppSelector(getProfileData);
  const chatId = useAppSelector(getChatId);
  const telegram = window.Telegram;

  const {
    control,
    register,
    setValue,
    setFocus,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<BasketForm>({
    defaultValues: {
      [BasketFormFields.PHONE]: '+380',
      [BasketFormFields.NAME]: '',
      [BasketFormFields.EMAIL]: '',
      [BasketFormFields.DELIVERY]:
        delivery_methods?.shop === false
          ? DeliveryTypes.DELIVERY
          : DeliveryTypes.SHOP,
    },
    resolver: yupResolver(orderScheme),
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (user?.phone) {
      setValue(BasketFormFields.PHONE, user.phone);
      setFocus(BasketFormFields.PHONE, {
        shouldSelect: true,
      });
    }
    setValue(BasketFormFields.NAME, user?.name || '');
    setValue(BasketFormFields.EMAIL, user?.email || '');
    setValue(BasketFormFields.ADDRESS, user?.delivery_address || '');
  }, [user, setValue, setFocus]);

  useEffect(() => {
    if (delivery_methods && delivery_methods.shop) {
      setValue(BasketFormFields.DELIVERY, DeliveryTypes.SHOP);
    }

    if (
      delivery_methods &&
      delivery_methods.delivery &&
      !delivery_methods.shop
    ) {
      setValue(BasketFormFields.DELIVERY, DeliveryTypes.DELIVERY);
    }
  }, [delivery_methods, setValue]);

  const total = useMemo(
    () =>
      String(
        basketProducts.reduce(
          (value, product) =>
            value + product.count * Number(product.product_price),
          0,
        ),
      ),
    [basketProducts],
  );

  const deliveryType = useWatch({
    control,
    name: BasketFormFields.DELIVERY,
  });

  console.log(watch(BasketFormFields.PHONE));

  useEffect(() => {
    if (deliveryType === DeliveryTypes.DELIVERY) {
      setValue(BasketFormFields.ADDRESS, '');
    }
  }, [deliveryType, setValue]);

  const handleInvoiceClosed = useCallback(
    (event: { status: string }) => {
      if (event.status === 'paid') {
        dispatch(resetBasketData());
        history.push(getSuccessPath());
      } else if (event.status === 'failed') {
        history.push(getDeniedPath());
      }
    },
    [dispatch],
  );

  useLayoutEffect(() => {
    if (addresses?.length) {
      setValue(BasketFormFields.SHOP, String(addresses[0].id));
    }
  }, [addresses, setValue]);

  const submit = useCallback(
    async (data: BasketForm) => {
      const response = await sendOrder({
        ...data,
        total,
        products: basketProducts,
        chat_id: chatId,
      });
      if (response.data.error) {
        telegram.WebApp.showAlert(response.data.error, () => {
          dispatch(updateToCurrentAmount(response.data.actual_count));
        });
        return;
      }

      if (data.payment_type === PayTypes.CARD) {
        telegram.WebApp.openInvoice(response.data.invoice_url);
        telegram.WebApp.onEvent('invoiceClosed', handleInvoiceClosed);
      } else {
        dispatch(resetBasketData());
        history.push(getSuccessPath());
      }
    },
    [total, basketProducts, dispatch, handleInvoiceClosed, chatId, telegram],
  );

  const handleBackspace = useCallback(() => history.back(), []);

  const handleSubmitForm = useCallback(
    () => handleSubmit(submit)(),
    [handleSubmit, submit],
  );

  useEffect(() => {
    if (telegram) {
      telegram.WebApp.MainButton.text = `Оплатити ${total} ${currency}`;

      if (basketProducts.length) {
        telegram.WebApp.MainButton.show();
      } else {
        telegram.WebApp.MainButton.hide();
      }

      telegram.WebApp.MainButton.onClick(handleSubmitForm);
      telegram.WebApp.BackButton.onClick(handleBackspace);
    }

    return () => {
      if (telegram) {
        telegram.WebApp.BackButton.offClick(handleBackspace);
        telegram.WebApp.MainButton.offClick(handleSubmitForm);
      }
    };
  }, [
    telegram,
    total,
    currency,
    basketProducts,
    handleSubmitForm,
    handleBackspace,
  ]);

  return (
    <Root>
      <Title>Кошик</Title>
      <List>
        {basketProducts.length
          ? basketProducts.map((product) => (
              <Item key={product.product_id} {...product} />
            ))
          : 'Немає товарів'}
      </List>
      <Paper>
        <AccordionStyled allowZeroExpanded>
          <AccordionItemStyled>
            <AccordionItemHeadingStyled>
              <AccordionItemButtonStyled>
                <PlusIcon />
                Додати коментар
              </AccordionItemButtonStyled>
            </AccordionItemHeadingStyled>
            <AccordionItemPanelStyled>
              <TextArea {...register(BasketFormFields.COMMENT)} />
            </AccordionItemPanelStyled>
          </AccordionItemStyled>
        </AccordionStyled>
      </Paper>
      <Paper>
        <Row>
          {delivery_methods && delivery_methods.shop && (
            <RadioButtonWrapper>
              <RadioInputButton
                defaultChecked
                type="radio"
                value={DeliveryTypes.SHOP}
                {...register(BasketFormFields.DELIVERY)}
              />
              <RadioButton>Доставка</RadioButton>
            </RadioButtonWrapper>
          )}
          {delivery_methods && delivery_methods.delivery && (
            <RadioButtonWrapper>
              <RadioInputButton
                defaultChecked={delivery_methods.shop === false}
                type="radio"
                value={DeliveryTypes.DELIVERY}
                {...register(BasketFormFields.DELIVERY)}
              />
              <RadioButton>Адресна доставка</RadioButton>
            </RadioButtonWrapper>
          )}
        </Row>
        {deliveryType === DeliveryTypes.SHOP ? (
          addresses?.map((address, index) => (
            <RadioInputWrapper key={address.id}>
              <RadioInput
                defaultChecked={index === 0}
                type="radio"
                value={address.id}
                {...register(BasketFormFields.SHOP)}
              />
              <RadioIcon />
              <Cell>
                <Label>{address.name}</Label>
                <RadioText>{address.address}</RadioText>
                <AddressTime>{address?.['work-time']}</AddressTime>
              </Cell>
            </RadioInputWrapper>
          ))
        ) : (
          <TextArea
            placeholder="Адреса"
            {...register(BasketFormFields.ADDRESS)}
            error={!!errors[BasketFormFields.ADDRESS]}
            message={errors[BasketFormFields.ADDRESS]?.message}
          />
        )}
      </Paper>
      <BasketShopForm errors={errors} register={register} />

      <Paper>
        <Label>Тип оплати</Label>
        {payment_methods?.cash && (
          <RadioInputWrapper>
            <RadioInput
              defaultChecked
              type="radio"
              value={PayTypes.CASH}
              {...register(BasketFormFields.TYPE)}
            />
            <RadioIcon />
            <Wallet style={{ marginLeft: '15px' }} />
            <Cell>
              <RadioText>Готівка</RadioText>
            </Cell>
          </RadioInputWrapper>
        )}
        {payment_methods?.card && (
          <RadioInputWrapper>
            <RadioInput
              defaultChecked={!payment_methods.cash}
              type="radio"
              value={PayTypes.CARD}
              {...register(BasketFormFields.TYPE)}
            />
            <RadioIcon />
            <Card style={{ marginLeft: '15px' }} />
            <Cell>
              <RadioText>Картою онлайн</RadioText>
            </Cell>
          </RadioInputWrapper>
        )}
      </Paper>
      {/* <Button onClick={handleSubmit(submit)}>
        <ButtonValue>
          {total} {currency}
        </ButtonValue>
        Оплатити
        <ButtonCount>{basketProducts.length}</ButtonCount>
      </Button> */}
    </Root>
  );
}
