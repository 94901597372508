import InputMask, { Props } from 'react-input-mask';
import styled from 'styled-components';

export interface InputMaskProps extends Props {
  maskChar?: string;
}

export const InputMaskStyled = styled(InputMask)<InputMaskProps>`
  width: 100%;
  background: ${({ theme }) => theme.background};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  margin-top: 10px;
  &::placeholder {
    color: ${({ theme }) => theme.text};
  }
`;
