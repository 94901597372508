import styled from 'styled-components';

export const Root = styled.div({
  position: 'relative',
});

export const Text = styled.p({
  margin: 0,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '120%',
  color: '#42526E',
});
