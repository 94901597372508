import styled from 'styled-components';
import { CategoryCard } from '../components';

export const Root = styled.div`
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.text};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled(CategoryCard)`
  & + & {
    margin-top: 5px;
  }
`;
