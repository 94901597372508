import { DefaultTheme } from 'styled-components';

export enum Colors {
  primary = 'primary',
  secondary = 'secondary',
  text = 'text',
  success = 'success',
  danger = 'danger',
  background = 'background',
}

export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
}

const darkPalette: DefaultTheme = {
  [Colors.primary]: '#19191B',
  [Colors.secondary]: '#19191B',
  [Colors.text]: '#FFF',
  [Colors.success]: '#44BD32',
  [Colors.danger]: '#C23616',
  [Colors.background]: '#242426',
};
const lightPalette: DefaultTheme = {
  [Colors.primary]: '#F1F2F6',
  [Colors.secondary]: '#FFF',
  [Colors.text]: '#333',
  [Colors.success]: '#44BD32',
  [Colors.danger]: '#C23616',
  [Colors.background]: '#fff',
};

export const palette = {
  [ThemeType.DARK]: darkPalette,
  [ThemeType.LIGHT]: lightPalette,
};
