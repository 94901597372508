import { ProductData } from '../products';

export interface BasketItem extends ProductData {
  count: number;
}

export interface BasketSliceState {
  products: BasketItem[];
  chatId?: string;
}

export enum ProductsAsyncActions {}

export enum BasketFormFields {
  COMMENT = 'note',
  SHOP = 'shop',
  NAME = 'name',
  PHONE = 'phone',
  TYPE = 'payment_type',
  DELIVERY = 'delivery_type',
  EMAIL = 'email',
  TOTAL = 'total',
  PRODUCTS = 'products',
  ADDRESS = 'address',

  STREET = 'street',
  HOUSE = 'house',
  INTERCOM = 'intercom',
  FLAT = 'flat',
  ENTRANCE = 'entrance',
  FLOOR = 'floor',
}

export enum DeliveryTypes {
  DELIVERY = 'delivery',
  SHOP = 'shop',
}

export enum PayTypes {
  CARD = 'card',
  CASH = 'cash',
}

export interface BasketForm {
  [BasketFormFields.NAME]: string;
  [BasketFormFields.SHOP]: string;
  [BasketFormFields.EMAIL]: string;
  [BasketFormFields.COMMENT]: string;
  [BasketFormFields.PHONE]: string;
  [BasketFormFields.TYPE]: string;
  [BasketFormFields.DELIVERY]: string;
  [BasketFormFields.ADDRESS]: string;

  [BasketFormFields.STREET]: string;
  [BasketFormFields.HOUSE]: string;
  [BasketFormFields.INTERCOM]: string;
  [BasketFormFields.FLAT]: string;
  [BasketFormFields.ENTRANCE]: string;
  [BasketFormFields.FLOOR]: string;
}
