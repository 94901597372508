import { ForwardedRef, forwardRef } from 'react';
import ReactInputMask, { Props } from 'react-input-mask';
import { InputMaskStyled } from './input-mask.styled';

export interface InputMaskProps extends Props {
  maskChar?: string;
}

function InputMask(props: InputMaskProps, ref: ForwardedRef<ReactInputMask>) {
  return <InputMaskStyled ref={ref} alwaysShowMask {...props} />;
}

export default forwardRef(InputMask);
