import { darken } from "polished";
import styled from "styled-components";
import { ReactComponent as BasketIcon } from "../../../assets/images/basket.svg";

export const Root = styled.div`
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.text};
`;

export const Preview = styled.div`
  width: 100%;
  height: 305px;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  background: ${({ theme }) => theme.secondary};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: -10px;
  padding: 19px 10px 10px 10px;
  z-index: 10;
  position: relative;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.text};
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  white-space: pre-line;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 21px;
  user-select: none;
  color: ${({ theme }) => theme.text};
`;

export const Price = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};
`;

export const Volume = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
`;

export const Button = styled.div`
  margin-top: auto;
  width: 100%;
  padding: 12px 22px;
  display: flex;
  align-items: center;
  transition: background-color 300ms ease-in;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #fff;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }) => theme.success};
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.success)};
  }
`;

export const Icon = styled(BasketIcon)`
  margin-left: 9px;
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 94px;
  height: 42px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.danger};
  &:hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.danger)};
  }
`;
