import { AsyncRequestState } from '../../../app/common-types';
import { Spinner } from '../../../app/components';
import { createAsyncRequestStateSelector } from '../../../app/selectors';
import { useAppSelector } from '../../../app/store';
import { getCategoriesAsync } from '../async-actions';
import { getCategories } from '../selectors';
import { Root, Title, List, Item } from './categories-screen.styled';

const selectLoadingState = createAsyncRequestStateSelector(
  [getCategoriesAsync.typePrefix],
  AsyncRequestState.pending,
);

export default function CategoriesScreen(): JSX.Element {
  const loading = useAppSelector(selectLoadingState);
  const categories = useAppSelector(getCategories);

  const renderCategories = () => {
    if (categories) {
      return (
        <List>
          {categories.map((category) => (
            <Item key={category.id} {...category} />
          ))}
        </List>
      );
    }

    return null;
  };

  return (
    <Root>
      <Title>Категорії</Title>
      {loading ? <Spinner /> : renderCategories()}
    </Root>
  );
}
