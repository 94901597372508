import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import vhCheck from 'vh-check';
import { App, persistor, store } from './app';
import { ThemeProvider } from './app/contexts';
import { GlobalStyle } from './app/styles/global-styles';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from './app/components/tostify.styled';

vhCheck();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <>
            <GlobalStyle />
            <App />
            <StyledToastContainer pauseOnHover={false} />
          </>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
