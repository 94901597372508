import styled from 'styled-components';

export const OrderStatusPaper = styled.div`
  background: ${({ theme }) => theme.secondary};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 34px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 24px;
`;

export const OrderStatusIconWrapper = styled.div`
  margin-bottom: 18px;
`;

export const OrderStatusTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  line-height: 120%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const OrderStatusText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  & + & {
    margin-top: 10px;
  }
  color: ${({ theme }) => theme.text};
`;

export const OrderStatusButton = styled.button`
  background: #44bd32;
  border-radius: 4px;
  padding: 6px 31px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 18px;
`;
