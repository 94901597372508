import { getProductsPath } from '../../../app/app-router-paths';
import { CategoryData } from '../types';
import {
  CategoryRoot,
  CategoryCardStyled,
  CategoryPreview,
  CategoryImg,
  CategoryName,
} from './category-card.styled';

export default function CategoryCard({
  image_url,
  id,
  name,
  ...otherProps
}: CategoryData) {
  return (
    <CategoryRoot to={getProductsPath(String(id))} {...otherProps}>
      <CategoryCardStyled>
        <CategoryPreview>
          <CategoryImg alt={name} src={image_url} />
        </CategoryPreview>
        <CategoryName>{name}</CategoryName>
      </CategoryCardStyled>
    </CategoryRoot>
  );
}
