import styled, { css } from "styled-components";
import { darken } from "polished";
import { NavLink } from "react-router-dom";
import { ReactComponent as BasketIcon } from "../../../assets/images/basket.svg";

export const ProductRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.secondary};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 100%;
`;

export const ProductPreview = styled(NavLink)`
  display: block;
  height: 96px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 17px;
`;

export const ProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ProductBody = styled.div`
  padding: 6px 9px 11px 9px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const ProductName = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 7px;
  color: ${({ theme }) => theme.text};
`;

export const ProductRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: auto;
`;

export const ProductPrice = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};
`;

export const ProductVolume = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
`;

export const sharedButton = css`
  width: 100%;
  padding: 8px 22px;
  display: flex;
  align-items: center;
  transition: background-color 300ms ease-in;
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
  cursor: pointer;
  user-select: none;
`;

export const ProductButton = styled.div`
  ${sharedButton};
  background-color: ${({ theme }) => theme.success};
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.success)};
  }
`;

export const Icon = styled(BasketIcon)`
  margin-left: 9px;
`;

export const DangerProductButton = styled.div`
  ${sharedButton};
  background-color: ${({ theme }) => theme.danger};
  justify-content: space-between;
  padding: 0;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      darken(0.1, theme.danger)};
  }
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
