import styled from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { ReactComponent as WalletIcon } from '../../../assets/images/wallet.svg';
import { ReactComponent as CardIcon } from '../../../assets/images/card.svg';
import { ReactComponent as IncrementIcon } from '../../../assets/images/plus.svg';
import { BasketProductCard } from '../components';

export const Root = styled.form`
  padding: 12px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  margin-bottom: 14px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const Item = styled(BasketProductCard)`
  & + & {
    margin-top: 12px;
  }
`;

export const Paper = styled.div`
  background: ${({ theme }) => theme.secondary};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
`;

export const AccordionStyled = styled(Accordion)``;
export const AccordionItemStyled = styled(AccordionItem)``;
export const AccordionItemHeadingStyled = styled(AccordionItemHeading)``;
export const AccordionItemButtonStyled = styled(AccordionItemButton)`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  display: flex;
  align-items: center;
`;

export const AccordionItemPanelStyled = styled(AccordionItemPanel)`
  padding-top: 12px;
`;

export const PlusIcon = styled(IncrementIcon)`
  fill: ${({ theme }) => theme.text};
  margin-right: 10px;
`;

export const Wallet = styled(WalletIcon)`
  fill: ${({ theme }) => theme.text};
`;

export const Card = styled(CardIcon)`
  fill: ${({ theme }) => theme.text};
`;

export const Label = styled.span`
  font-weight: 700;
  display: block;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const RadioInputWrapper = styled.label`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 14px;
  }
`;

export const RadioIcon = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.text};
`;

export const RadioInput = styled.input`
  appearance: none;
  display: none;
  width: 0px;
  height: 0px;
  &:checked ~ ${RadioIcon} {
    border: 1px solid ${({ theme }) => theme.danger};
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.danger};
    }
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const RadioText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
`;

export const AddressTime = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: #9d9c9c;
  margin-top: 6px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RadioButtonWrapper = styled.label`
  cursor: pointer;
  flex-grow: 1;
  position: relative;
  margin-bottom: 15px;
  & + & {
    margin-left: 12px;
  }
`;

export const RadioButton = styled.div`
  padding: 6px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
`;

export const RadioInputButton = styled.input`
  appearance: none;
  position: absolute;
  &:checked ~ ${RadioButton} {
    background-color: ${({ theme }) => theme.danger};
    color: #fff;
  }
`;

export const Button = styled.button`
  width: 100%;
  border-radius: 6px;
  border: none;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.success};
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
`;

export const ButtonValue = styled.span`
  position: absolute;
  left: 13px;
  bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
`;

export const ButtonCount = styled.span`
  position: absolute;
  right: 11px;
  bottom: 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: 0.02em;
`;
