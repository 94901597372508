import { BasketItem } from '../types';
import {
  Root,
  Preview,
  Image,
  Body,
  Name,
  Row,
  Price,
  Volume,
  Button,
} from './basket-product-card.styled';
import { ReactComponent as IncrementIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as DecrementIcon } from '../../../assets/images/minus.svg';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { decrementCount, incrementCount, removeProduct } from '../slice';

export default function BasketProductCard({
  product_id,
  product_image_url,
  product_name,
  product_price,
  product_count,
  count,
  ...otherProps
}: BasketItem) {
  const dispatch = useAppDispatch();
  const currency =
    useAppSelector((state) => state.profile.data.currency) || 'грн.';

  const increment = () =>
    count < Number(product_count) && dispatch(incrementCount(product_id));

  const decrement = () =>
    count === 1
      ? dispatch(removeProduct(product_id))
      : dispatch(decrementCount(product_id));

  return (
    <Root {...otherProps}>
      <Preview>
        <Image alt={product_name} src={product_image_url} />
      </Preview>
      <Body>
        <Name>{product_name}</Name>
        <Row>
          <Price>
            {product_price} {currency}
          </Price>
          <Volume>{product_count} шт.</Volume>
        </Row>
        <Row>
          <Button onClick={decrement}>
            <DecrementIcon />
          </Button>
          {count}
          <Button onClick={increment}>
            <IncrementIcon />
          </Button>
        </Row>
      </Body>
    </Root>
  );
}
