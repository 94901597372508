import styled from 'styled-components';
import { TextField } from '../../../app/components';

export const BasketDeliveryFormLabel = styled.span`
  font-weight: 700;
  display: block;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const BasketDeliveryFormInput = styled(TextField)`
  margin-top: 10px;
`;

export const BasketDeliveryFormRow = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -12px;
`;
export const BasketDeliveryFormCell = styled('div')`
  width: calc(50% - 12px);
  margin-left: 12px;
`;
