import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const CategoryRoot = styled(NavLink)``;

export const CategoryCardStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0px 4px 4px 0px;
`;

export const CategoryPreview = styled.div`
  width: 125px;
  height: 82px;
  flex-shrink: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 17px;
`;

export const CategoryImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CategoryName = styled.span`
  color: ${({ theme }) => theme.text};
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
`;
