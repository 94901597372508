import { createContext } from 'react';
import { DefaultTheme } from 'styled-components';
import { ThemeType } from '../styles/theme';

export interface ThemeContextData {
  toggleTheme: (newThemeType: ThemeType) => void;
  theme?: DefaultTheme;
  themeType: ThemeType;
}

export const ThemeContext = createContext<ThemeContextData>({
  toggleTheme: (newThemeType: ThemeType) => newThemeType,
  themeType: ThemeType.LIGHT,
});
