import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputStyled = styled.input`
  background: ${({ theme }) => theme.background};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  margin-bottom: 2px;
  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.text};
  }
`;

export const InputError = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};
  display: block;
`;
