import axios from 'axios';
import { setupApiHeaders } from './setup-api-headers';

const BASE_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
});

export default setupApiHeaders(apiClient);
