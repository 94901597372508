import { history } from '../utils/history';
import notification from './notifications-service';
import * as categories from './categories-service';
import * as products from './products-service';
import * as product from './product-service';
import * as profile from './profile-service';

export default {
  api: {
    profile,
    categories,
    products,
    product,
  },
  notification,
  history,
};
