export interface CategoryData {
  id: number;
  image_url: string;
  name: string;
}

export interface CategoriesSliceState {
  data: CategoryData[] | null;
}

export enum CategoriesAsyncActions {
  GET_CATEGORIES = 'getCategories',
}
