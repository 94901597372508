import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { getProductByIdAsync } from './async-actions';
import { ProductSliceState } from './types';

const initialState: ProductSliceState = {
  data: null,
};

const productSlice = createSlice({
  name: Features.products,
  initialState,
  reducers: {
    resetProductData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductByIdAsync.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    return builder;
  },
});

export const { resetProductData } = productSlice.actions;

export default productSlice.reducer;
