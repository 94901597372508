import styled from 'styled-components';
import { ReactComponent as BasketIcon } from '../../assets/images/basket.svg';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - var(--vh-offset, 0px));
`;

export const BasketIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: red;
  position: absolute;
  right: 30px;
  top: 100px;
  z-index: 100;
`;

export const BasketIconStyled = styled(BasketIcon)``;
