import styled from 'styled-components';

export const Root = styled.div`
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.text};
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  width: calc(100% + 12px);
`;

export const CardWrapper = styled.div`
  width: calc(100% / 2 - 12px);
  margin-left: 12px;
  margin-bottom: 12px;
`;
