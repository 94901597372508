import { darken } from "polished";
import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  background: ${({ theme }) => theme.secondary};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const Preview = styled.div`
  display: flex;
  width: 117px;
  max-height: 115px;
  border-radius: 4px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Body = styled.div`
  padding: 9px 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Name = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  margin-bottom: 6px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};

  letter-spacing: 0.02em;
  & + & {
    margin-top: 12px;
  }
`;

export const Price = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};
`;

export const Volume = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
`;

export const Button = styled.div`
  width: 54px;
  height: 30px;
  background: ${({ theme }) => theme.danger};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => darken(0.1, theme.danger)};
  }
`;
