import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/store";
import { getProductByIdAsync } from "../async-actions";
import { getProduct } from "../selectors";

import { ReactComponent as IncrementIcon } from "../../../assets/images/plus.svg";
import { ReactComponent as DecrementIcon } from "../../../assets/images/minus.svg";
import {
  Root,
  Title,
  Preview,
  Image,
  Body,
  Description,
  Row,
  Price,
  Volume,
  Button,
  Counter,
  Icon,
} from "./product-screen.styled";

import {
  addProduct,
  decrementCount,
  incrementCount,
  removeProduct,
} from "../../basket";
import { createAsyncRequestStateSelector } from "../../../app/selectors";
import { AsyncRequestState } from "../../../app/common-types";
import { Spinner } from "../../../app/components";

interface PageParams extends Record<string, string> {
  id: string;
}

const selectLoadingState = createAsyncRequestStateSelector(
  [getProductByIdAsync.typePrefix],
  AsyncRequestState.pending
);

export default function ProductScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const product = useAppSelector(getProduct);
  const loading = useAppSelector(selectLoadingState);
  const params = useParams<PageParams>();
  const currency =
    useAppSelector(
      (state) => state.profile.data.currency
    ) || "грн.";
  const id = params.id as string;

  const basketProducts = useAppSelector((state) =>
    state.basket.products.filter(
      (p) => p.product_id === Number(id)
    )
  );

  useEffect(() => {
    dispatch(getProductByIdAsync(id));
  }, [dispatch, id]);

  const addProductInBasket = () => {
    dispatch(addProduct(product));
  };

  const decrement = () =>
    basketProducts[0].count === 1
      ? dispatch(removeProduct(Number(id)))
      : dispatch(decrementCount(Number(id)));

  const increment = () =>
    basketProducts[0].count <
      Number(product?.product_count) &&
    dispatch(incrementCount(Number(id)));

  const renderButton = () => {
    if (basketProducts[0]) {
      return (
        <Row>
          <Counter aria-hidden="true" onClick={decrement}>
            <DecrementIcon />
          </Counter>
          {basketProducts[0].count}
          <Counter aria-hidden="true" onClick={increment}>
            <IncrementIcon />
          </Counter>
        </Row>
      );
    }

    return (
      <Button onClick={addProductInBasket}>
        В кошик <Icon />
      </Button>
    );
  };

  return (
    <Root>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Title>{product?.product_name}</Title>
          <Preview>
            <Image
              alt={product?.product_name}
              src={product?.product_image_url}
            />
          </Preview>
          <Body>
            <Title>{product?.product_name}</Title>
            <Description>
              {product?.product_description}
              <p>Об`єм: {product?.product_volume}</p>
            </Description>
            <Row>
              <Price>
                {product?.product_price} {currency}
              </Price>
              <Volume>{product?.product_count} шт.</Volume>
            </Row>
            {renderButton()}
          </Body>
        </>
      )}
    </Root>
  );
}
