import { Oval } from 'react-loader-spinner';
import { SpinnerStyled } from './spinner.styled';

function Spinner() {
  return (
    <SpinnerStyled>
      <Oval
        ariaLabel="oval-loading"
        color="#4fa94d"
        height={60}
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
        visible
        width={60}
      />
    </SpinnerStyled>
  );
}

export default Spinner;
