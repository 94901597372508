import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { BasketSliceState } from './types';

const initialState: BasketSliceState = {
  products: [],
  chatId: undefined,
};

const basketSlice = createSlice({
  name: Features.basket,
  initialState,
  reducers: {
    resetBasketData(state) {
      state.products = [];
    },
    setChatId(state, { payload }) {
      state.chatId = payload;
    },
    incrementCount(state, { payload }) {
      state.products = [
        ...state.products.map((product) => {
          if (product.product_id === payload) {
            return { ...product, count: product.count + 1 };
          }
          return product;
        }),
      ];
    },
    decrementCount(state, { payload }) {
      state.products = [
        ...state.products.map((product) => {
          if (product.product_id === payload) {
            return { ...product, count: product.count - 1 };
          }
          return product;
        }),
      ];
    },
    updateToCurrentAmount(state, { payload }) {
      if (payload) {
        state.products = state.products.map((product) => ({
          ...product,
          count: payload[product.product_id]
            ? payload[product.product_id]
            : product.count,
        }));
      }
    },
    addProduct(state, { payload }) {
      state.products = [...state.products, { ...payload, count: 1 }];
    },
    removeProduct(state, { payload }) {
      state.products = state.products.filter(
        (product) => product.product_id !== payload,
      );
    },
  },
});

export const {
  resetBasketData,
  setChatId,
  addProduct,
  decrementCount,
  incrementCount,
  removeProduct,
  updateToCurrentAmount,
} = basketSlice.actions;

export default basketSlice.reducer;
