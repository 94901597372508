import { ProductData } from '../../features/products';
import apiClient from '../utils/api-client';

export async function getProductById(id: string) {
  try {
    const response = await apiClient.get<ProductData>(
      `/api.php?method=get-product-by-id&product_id=${id}`,
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
