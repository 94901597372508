import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getActionTypePrefix } from '../../app/utils';
import { ThunkApi } from '../../app/common-types';
import { Features } from '../types';
import { CategoriesAsyncActions, CategoryData } from './types';

export const getCategoriesAsync = createAsyncThunk<
  CategoryData[],
  undefined,
  ThunkApi
>(
  getActionTypePrefix<CategoriesAsyncActions>(
    Features.categories,
    CategoriesAsyncActions.GET_CATEGORIES,
  ),
  async (_, { rejectWithValue, extra: { api, notification } }) => {
    try {
      const categories = await api.categories.getCategories();
      return categories;
    } catch (e) {
      const error = e as AxiosError;
      notification({
        type: 'error',
        message: error.response?.data.error || error.message,
      });
      return rejectWithValue(error.message);
    }
  },
);
