import { generatePath } from 'react-router-dom';

export enum PathsMap {
  NOT_FOUND = '*',
  CATEGORIES = '/',
  PRODUCTS = '/products/:id',
  PRODUCT = '/product/:id',
  BASKET = '/basket',
  SUCCESS = '/success',
  DENIED = '/denied',
}

export const getNotFoundPath = () => generatePath(PathsMap.NOT_FOUND);

export const getCategoriesPath = () => generatePath(PathsMap.CATEGORIES);

export const getProductsPath = (id: string) =>
  generatePath(PathsMap.PRODUCTS, {
    id,
  });

export const getProductPath = (id: string) =>
  generatePath(PathsMap.PRODUCT, {
    id,
  });

export const getBasketPath = () => generatePath(PathsMap.BASKET);
export const getSuccessPath = () => generatePath(PathsMap.SUCCESS);
export const getDeniedPath = () => generatePath(PathsMap.DENIED);
