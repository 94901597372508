import { ForwardedRef, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { InputStyled, InputWrapper, InputError } from './text-field.styled';

export interface TextFieldProps extends UseFormRegisterReturn {
  className?: string;
  placeholder?: string;
  error: boolean;
  message?: string;
}

function TextField(
  { className, error, message, ...otherProps }: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <InputWrapper ref={ref} className={className}>
      <InputStyled {...otherProps} />
      {error && <InputError>{message}</InputError>}
    </InputWrapper>
  );
}

export default forwardRef(TextField);
