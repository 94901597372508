import { ForwardedRef, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  TextAreaWrapper,
  TextAreaStyled,
  TextAreaError,
} from './textarea.styled';

export interface TextAreaProps extends UseFormRegisterReturn {
  className?: string;
  placeholder?: string;
  error?: boolean;
  message?: string;
}

function TextArea(
  { className, error, message, ...otherProps }: TextAreaProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <TextAreaWrapper ref={ref} className={className}>
      <TextAreaStyled {...otherProps} />
      {error && <TextAreaError>{message}</TextAreaError>}
    </TextAreaWrapper>
  );
}

export default forwardRef(TextArea);
