import { BasketForm, BasketItem } from '../../features/basket';
import { apiClient } from '../utils';

export interface OrderForm extends BasketForm {
  total: string;
  products: BasketItem[];
  chat_id?: string;
}

export async function sendOrder(body: OrderForm) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { products, ...otherBody } = body as any;

    const formData = new FormData();
    Object.keys(otherBody).forEach((key) =>
      formData.append(key, otherBody[key]),
    );
    formData.append('products', JSON.stringify(products));

    const response = await apiClient.post(
      `https://forms.builder.kolisar.com/AlkoShop/api.php?method=create-order`,
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}
