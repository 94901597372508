export interface ProductData {
  product_description: string;
  product_id: number;
  product_image_url: string;
  product_name: string;
  product_price: string;
  product_volume: string;
  product_count: string;
}

export interface ProductsSliceState {
  data: ProductData[] | null;
}

export enum ProductsAsyncActions {
  GET_PRODUCTS = "getProducts",
}
