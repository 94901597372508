import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { getCategoriesAsync } from './async-actions';
import { CategoriesSliceState } from './types';

const initialState: CategoriesSliceState = {
  data: null,
};

const categoriesSlice = createSlice({
  name: Features.categories,
  initialState,
  reducers: {
    resetCategoriesData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesAsync.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    return builder;
  },
});

export const { resetCategoriesData } = categoriesSlice.actions;

export default categoriesSlice.reducer;
