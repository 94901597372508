import { ProductData } from '../../features/products';
import apiClient from '../utils/api-client';

export async function getProductsByCategory(id: string) {
  try {
    const response = await apiClient.get<ProductData[]>(
      `/api.php?method=get-products-by-category&category_id=${id}`,
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
