import * as yup from 'yup';
import { BasketFormFields, DeliveryTypes } from './types';

export const orderScheme = yup.object().shape({
  [BasketFormFields.NAME]: yup.string().required('Enter name*'),
  [BasketFormFields.PHONE]: yup
    .string()
    .test('Invalid phone*', (value) => value?.indexOf('_') === -1)
    .required('Enter phone*')
    .test('Invalid length*', (value) => {
      const replacePhone = value?.replace(/\D/g, '');
      return replacePhone?.length === 12;
    }),

  [BasketFormFields.EMAIL]: yup.string().email().required('Enter email*'),
  [BasketFormFields.ADDRESS]: yup.string().when(BasketFormFields.DELIVERY, {
    is: DeliveryTypes.DELIVERY,
    then: yup.string().required('Enter address*'),
  }),
  // [BasketFormFields.STREET]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter street*'),
  // }),
  // [BasketFormFields.HOUSE]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter house*'),
  // }),
  // [BasketFormFields.INTERCOM]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter intercom*'),
  // }),
  // [BasketFormFields.FLAT]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter flat*'),
  // }),
  // [BasketFormFields.ENTRANCE]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter entrance*'),
  // }),
  // [BasketFormFields.FLOOR]: yup.string().when(BasketFormFields.DELIVERY, {
  //   is: DeliveryTypes.DELIVERY,
  //   then: yup.string().required('Enter floor*'),
  // }),
});
