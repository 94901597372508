import styled from 'styled-components';
import { TextField } from '../../../app/components';

export const BasketShopFormLabel = styled.span`
  font-weight: 700;
  display: block;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

export const BasketShopFormInput = styled(TextField)`
  margin-top: 10px;
`;
