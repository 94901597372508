import { ProductData } from "../types";
import {
  ProductRoot,
  ProductPreview,
  ProductImg,
  ProductBody,
  ProductName,
  ProductRow,
  ProductPrice,
  ProductVolume,
  DangerProductButton,
  ProductButton,
  IconWrapper,
  Icon,
} from "./product-card.styled";

import { ReactComponent as IncrementIcon } from "../../../assets/images/plus.svg";
import { ReactComponent as DecrementIcon } from "../../../assets/images/minus.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../app/store";
import {
  addProduct,
  decrementCount,
  incrementCount,
  removeProduct,
} from "../../basket";
import { getProductPath } from "../../../app/app-router-paths";

export default function ProductCard(product: ProductData) {
  const {
    product_id,
    product_image_url,
    product_name,
    product_price,
    product_count,
  } = product;
  const dispatch = useAppDispatch();
  const basketProducts = useAppSelector((state) =>
    state.basket.products.filter(
      (p) => p.product_id === product_id
    )
  );
  const currency =
    useAppSelector(
      (state) => state.profile.data.currency
    ) || "грн.";

  const addProductInBasket = () => {
    dispatch(addProduct(product));
  };

  const decrement = () =>
    basketProducts[0].count === 1
      ? dispatch(removeProduct(product_id))
      : dispatch(decrementCount(product_id));

  const increment = () =>
    basketProducts[0].count < Number(product_count) &&
    dispatch(incrementCount(product_id));

  const renderButton = () => {
    if (basketProducts[0]) {
      return (
        <DangerProductButton>
          <IconWrapper
            aria-hidden="true"
            onClick={decrement}
          >
            <DecrementIcon />
          </IconWrapper>
          {basketProducts[0].count}
          <IconWrapper
            aria-hidden="true"
            onClick={increment}
          >
            <IncrementIcon />
          </IconWrapper>
        </DangerProductButton>
      );
    }

    return (
      <ProductButton onClick={addProductInBasket}>
        В кошик <Icon />
      </ProductButton>
    );
  };

  return (
    <ProductRoot>
      <ProductPreview
        to={getProductPath(String(product_id))}
      >
        <ProductImg
          alt={product_name}
          src={product_image_url}
        />
      </ProductPreview>
      <ProductBody>
        <ProductName>{product_name}</ProductName>
        <ProductRow>
          <ProductPrice>
            {product_price} {currency}
          </ProductPrice>
          <ProductVolume>{product_count} шт.</ProductVolume>
        </ProductRow>
        {renderButton()}
      </ProductBody>
    </ProductRoot>
  );
}
