import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
// import * as ls from 'local-storage';
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
// import { LocalStorageData } from '../common-types';
import { ThemeContext } from './theme-context';
import { palette, ThemeType } from '../styles/theme';

interface ThemeProviderProps {
  children: ReactElement;
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.DARK);
  const [theme, setTheme] = useState<DefaultTheme>(palette[ThemeType.DARK]);

  // useLayoutEffect(() => {
  //   const userThemeType = ls.get(LocalStorageData.THEME_STORAGE) as
  //     | ThemeType
  //     | undefined;

  //   if (userThemeType) {
  //     setThemeType(userThemeType);
  //   }
  // }, []);

  const toggleTheme = useCallback((newThemeType: ThemeType) => {
    setThemeType(newThemeType);
    // ls.set(LocalStorageData.THEME_STORAGE, newThemeType);
  }, []);

  useEffect(() => {
    const currentPalette = palette[themeType];
    setTheme(currentPalette);
  }, [themeType]);

  const value = useMemo(
    () => ({
      themeType,
      toggleTheme,
      theme,
    }),
    [themeType, toggleTheme, theme],
  );

  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
