import { ReactElement } from 'react';
import { ContainerStyled } from './container.styled';

export interface ContainerProps {
  children?: ReactElement;
}

export default function Container({ children, ...props }: ContainerProps) {
  return <ContainerStyled {...props}>{children}</ContainerStyled>;
}
