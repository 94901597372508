import { DeliveryTypes, PayTypes } from '../basket';

export interface DeliveryMethods {
  [DeliveryTypes.SHOP]: boolean;
  [DeliveryTypes.DELIVERY]: boolean;
}

export interface PaymentMethods {
  [PayTypes.CARD]: boolean;
  [PayTypes.CASH]: boolean;
}

export interface UserData {
  name: string;
  phone: string;
  email: string;
  delivery_address: string;
}

export interface ProfileData {
  user?: UserData;
  payment_methods?: PaymentMethods;
  delivery_methods?: DeliveryMethods;
  currency?: string;
}

export interface ProfileSliceState {
  data: ProfileData;
}

export enum ProfileAsyncActions {
  GET_PROFILE_DATA = 'getProfileData',
}
