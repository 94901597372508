import { ProfileData } from '../../features/profile';
import apiClient from '../utils/api-client';

export async function getProfileData(id: string) {
  try {
    const response = await apiClient.get<ProfileData>(
      `/api.php?method=get-cart-config&chat_id=${id}`,
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
