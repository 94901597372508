import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { getProductsByCategoryAsync } from './async-actions';
import { ProductsSliceState } from './types';

const initialState: ProductsSliceState = {
  data: null,
};

const productsSlice = createSlice({
  name: Features.products,
  initialState,
  reducers: {
    resetProductsData(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductsByCategoryAsync.fulfilled,
      (state, { payload }) => {
        state.data = payload;
      },
    );

    return builder;
  },
});

export const { resetProductsData } = productsSlice.actions;

export default productsSlice.reducer;
