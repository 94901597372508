import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { InputMask } from '../../../app/components';
import { InputError } from '../../../app/components/text-field.styled';
import { Paper } from '../screens/basket-screen.styled';
import { BasketForm, BasketFormFields } from '../types';
import {
  BasketDeliveryFormLabel,
  BasketDeliveryFormInput,
  BasketDeliveryFormRow,
  BasketDeliveryFormCell,
} from './basket-delivery-form.styled';

export interface BasketDeliveryFormProps {
  register: UseFormRegister<BasketForm>;
  errors: Partial<FieldErrorsImpl<BasketForm>>;
}

function BasketDeliveryForm({ register, errors }: BasketDeliveryFormProps) {
  return (
    <Paper>
      <BasketDeliveryFormLabel>Дані про доставку</BasketDeliveryFormLabel>
      <BasketDeliveryFormInput
        error={!!errors[BasketFormFields.NAME]}
        placeholder="І’мя"
        {...register(BasketFormFields.NAME)}
        message={errors[BasketFormFields.NAME]?.message}
      />
      <InputMask
        alwaysShowMask
        mask="+999 (99) 99 99 999"
        maskPlaceholder="+380 (ХХ) ХХХ ХХ ХХ*"
        {...register(BasketFormFields.PHONE)}
      />
      {!!errors[BasketFormFields.PHONE] && (
        <InputError>{errors[BasketFormFields.PHONE]?.message}</InputError>
      )}
      <BasketDeliveryFormInput
        error={!!errors[BasketFormFields.EMAIL]}
        placeholder="Email*"
        {...register(BasketFormFields.EMAIL)}
        message={errors[BasketFormFields.EMAIL]?.message}
      />
      <BasketDeliveryFormInput
        error={!!errors[BasketFormFields.STREET]}
        placeholder="Вулиця*"
        {...register(BasketFormFields.STREET)}
        message={errors[BasketFormFields.STREET]?.message}
      />
      <BasketDeliveryFormInput
        error={!!errors[BasketFormFields.HOUSE]}
        placeholder="Дім*"
        {...register(BasketFormFields.HOUSE)}
        message={errors[BasketFormFields.HOUSE]?.message}
      />
      <BasketDeliveryFormRow>
        <BasketDeliveryFormCell>
          <BasketDeliveryFormInput
            error={!!errors[BasketFormFields.INTERCOM]}
            placeholder="Домофон*"
            {...register(BasketFormFields.INTERCOM)}
            message={errors[BasketFormFields.INTERCOM]?.message}
          />
        </BasketDeliveryFormCell>
        <BasketDeliveryFormCell>
          <BasketDeliveryFormInput
            error={!!errors[BasketFormFields.FLAT]}
            placeholder="Квартира*"
            {...register(BasketFormFields.FLAT)}
            message={errors[BasketFormFields.FLAT]?.message}
          />
        </BasketDeliveryFormCell>
      </BasketDeliveryFormRow>
      <BasketDeliveryFormRow>
        <BasketDeliveryFormCell>
          <BasketDeliveryFormInput
            error={!!errors[BasketFormFields.ENTRANCE]}
            placeholder="Під'їзд*"
            {...register(BasketFormFields.ENTRANCE)}
            message={errors[BasketFormFields.ENTRANCE]?.message}
          />
        </BasketDeliveryFormCell>
        <BasketDeliveryFormCell>
          <BasketDeliveryFormInput
            error={!!errors[BasketFormFields.FLOOR]}
            placeholder="Поверх*"
            {...register(BasketFormFields.FLOOR)}
            message={errors[BasketFormFields.FLOOR]?.message}
          />
        </BasketDeliveryFormCell>
      </BasketDeliveryFormRow>
    </Paper>
  );
}

export default BasketDeliveryForm;
