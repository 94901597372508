import { useCallback, useEffect, useLayoutEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { setChatId } from '../../features/basket';
import { getProductsFromBasket } from '../../features/basket/selectors';
import { getProfileAsync } from '../../features/profile/async-actions';
import {
  getBasketPath,
  getCategoriesPath,
  getSuccessPath,
} from '../app-router-paths';
import { Container } from '../components';
import { useTheme } from '../hooks';
import { useAppDispatch, useAppSelector } from '../store';
import { ThemeType } from '../styles/theme';
import { history } from '../utils';
import { Root, Content, Wrapper } from './dashboard-layout.styled';
// import Footer from './footer';

export default function DashboardLayout() {
  const dispatch = useAppDispatch();
  const { toggleTheme } = useTheme();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const chat_id = params.get('chat_id');
  const basketProducts = useAppSelector(getProductsFromBasket);

  const telegram = window.Telegram;

  const toBasketPage = useCallback(() => history.push(getBasketPath()), []);
  const handleBackspace = useCallback(
    () =>
      pathname === getSuccessPath()
        ? history.replace(getCategoriesPath())
        : history.back(),
    [pathname],
  );

  useEffect(() => {
    if (chat_id) {
      dispatch(setChatId(chat_id));
      dispatch(getProfileAsync(chat_id));
    }
    // dispatch(setChatId('733407573'));
    // dispatch(getProfileAsync('733407573'));
  }, [chat_id, dispatch]);

  useLayoutEffect(() => {
    if (telegram) {
      telegram.WebApp.expand();
      const theme = telegram.WebApp.colorScheme as ThemeType;
      toggleTheme(theme);

      if (pathname !== getBasketPath()) {
        telegram.WebApp.MainButton.text = 'Кошик';
        telegram.WebApp.MainButton.onClick(toBasketPage);
        if (basketProducts.length) {
          telegram.WebApp.MainButton.show();
        } else {
          telegram.WebApp.MainButton.hide();
        }
      }

      telegram.WebApp.BackButton.onClick(handleBackspace);

      if (pathname === getCategoriesPath()) {
        telegram.WebApp.BackButton.hide();
      } else {
        telegram.WebApp.BackButton.show();
      }
    }

    return () => {
      if (telegram) {
        telegram.WebApp.BackButton.offClick(handleBackspace);

        if (pathname !== getBasketPath()) {
          telegram.WebApp.MainButton.offClick(toBasketPage);
        }
      }
    };
  }, [
    telegram,
    toggleTheme,
    pathname,
    basketProducts,
    handleBackspace,
    toBasketPage,
  ]);

  return (
    <Root>
      <Container>
        <Wrapper>
          <Content>
            <Outlet />
          </Content>
          {/* <Footer /> */}
        </Wrapper>
      </Container>
    </Root>
  );
}
