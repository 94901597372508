import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AsyncRequestState } from '../../../app/common-types';
import { Spinner } from '../../../app/components';
import { createAsyncRequestStateSelector } from '../../../app/selectors';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { getProductsByCategoryAsync } from '../async-actions';
import { ProductCard } from '../components';
import { getProducts } from '../selectors';
import { Root, Title, List, CardWrapper } from './products-screen.styled';

interface PageParams extends Record<string, string> {
  id: string;
}

const selectLoadingState = createAsyncRequestStateSelector(
  [getProductsByCategoryAsync.typePrefix],
  AsyncRequestState.pending,
);

export default function ProductsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoadingState);
  const products = useAppSelector(getProducts);
  const params = useParams<PageParams>();

  const id = params.id as string;

  const currentCategory = useAppSelector(
    (state) =>
      state.categories.data?.filter(
        (category) => category.id === Number(params.id),
      )[0],
  );

  useEffect(() => {
    dispatch(getProductsByCategoryAsync(id));
  }, [dispatch, id]);

  return (
    <Root>
      <Title>{currentCategory?.name}</Title>
      {loading ? (
        <Spinner />
      ) : (
        <List>
          {products?.map((product) => (
            <CardWrapper key={product.product_id}>
              <ProductCard {...product} />
            </CardWrapper>
          ))}
        </List>
      )}
    </Root>
  );
}
