import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreaStyled = styled.textarea`
  background: ${({ theme }) => theme.background};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.text};
  resize: none;
  width: 100%;
  height: 100px;
  margin-bottom: 2px;
  &::placeholder {
    color: ${({ theme }) => theme.text};
  }
`;

export const TextAreaError = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  color: ${({ theme }) => theme.danger};
  display: block;
`;
