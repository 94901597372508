import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Features } from '../../types';

export interface ShopData {
  id: number;
  name: string;
  address: string;
  ['work-time']: string;
}

export const basketApi = createApi({
  reducerPath: Features.basketApi,
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://forms.builder.kolisar.com/AlkoShop',
  }),
  endpoints: (builder) => ({
    getShops: builder.query<ShopData[], void>({
      query: () => `/api.php?method=get-shops-list`,
    }),
  }),
});

export const { useGetShopsQuery } = basketApi;
