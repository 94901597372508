import { createSlice } from '@reduxjs/toolkit';
import { Features } from '../types';
import { getProfileAsync } from './async-actions';
import { ProfileSliceState } from './types';

const initialState: ProfileSliceState = {
  data: {},
};

const profileSlice = createSlice({
  name: Features.profile,
  initialState,
  reducers: {
    resetProfileData(state) {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileAsync.fulfilled, (state, { payload }) => {
      state.data = payload;
    });

    return builder;
  },
});

export const { resetProfileData } = profileSlice.actions;

export default profileSlice.reducer;
