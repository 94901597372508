import { useEffect } from 'react';
import { getCategoriesAsync } from '../features/categories/async-actions';
import AppRouter from './app-router';
import { useAppDispatch } from './store';

export default function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  return <AppRouter />;
}
