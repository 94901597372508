import styled from 'styled-components';

export const FooterStyled = styled.footer`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  padding: 16px 25px 7px 25px;
`;

export const FooterStart = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterEnd = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterImg = styled.img``;
