import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { BasketScreen, DeniedScreen, SuccessScreen } from '../features/basket';
import { CategoriesScreen } from '../features/categories';
import ProductScreen from '../features/product/screens/product-screen';
import { ProductsScreen } from '../features/products';

import { PathsMap } from './app-router-paths';
import { DashboardLayout } from './layouts';
import NotFoundPage from './pages/not-found-page';

export enum RouteType {
  COMMON = 'common',
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface Route {
  path: string;
  element: ReactElement;
  children?: Route[];
}

export type Routes = Record<RouteType, Route[]>;
export type Layouts = Record<RouteType, ReactElement>;

export const routes: Routes = {
  [RouteType.COMMON]: [
    {
      path: PathsMap.NOT_FOUND,
      element: <NotFoundPage />,
    },
  ],
  [RouteType.PUBLIC]: [
    {
      path: PathsMap.CATEGORIES,
      element: <CategoriesScreen />,
    },
    {
      path: PathsMap.PRODUCTS,
      element: <ProductsScreen />,
    },
    {
      path: PathsMap.PRODUCT,
      element: <ProductScreen />,
    },
    {
      path: PathsMap.BASKET,
      element: <BasketScreen />,
    },
    {
      path: PathsMap.SUCCESS,
      element: <SuccessScreen />,
    },
    {
      path: PathsMap.DENIED,
      element: <DeniedScreen />,
    },
  ],
  [RouteType.PRIVATE]: [],
};

export const layouts: Layouts = {
  [RouteType.PRIVATE]: <Outlet />,
  [RouteType.PUBLIC]: <DashboardLayout />,
  [RouteType.COMMON]: <Outlet />,
};
