import {
  // OrderStatusButton,
  OrderStatusIconWrapper,
  OrderStatusPaper,
  OrderStatusText,
  OrderStatusTitle,
} from './order-status.styled';

import { ReactComponent as SuccessIcon } from '../../../assets/images/success.svg';
import { ReactComponent as DeniedIcon } from '../../../assets/images/denied.svg';
// import { ReactComponent as HelpIcon } from '../../../assets/images/help.svg';

export enum OrderStatusEnum {
  SUCCESS = 'success',
  DENIED = 'denied',
}

export interface OrderStatusProps {
  status: OrderStatusEnum;
}

function OrderStatus({ status }: OrderStatusProps) {
  const title =
    status === OrderStatusEnum.SUCCESS
      ? 'Дякуємо за замовлення!'
      : 'Оплата відхилена платіжною системою!';

  const text =
    status === OrderStatusEnum.SUCCESS
      ? [
          'Оплата пройшла успішно, чек буде відправлено на вказаний Вами e-mail.',
          "Ми зв'яжемося з вами протягом 5 хвилин.",
        ]
      : ['Спробуйте ще раз або зверніться за допомогою до Вашого банку.'];

  return (
    <OrderStatusPaper>
      <OrderStatusIconWrapper>
        {status === OrderStatusEnum.SUCCESS ? <SuccessIcon /> : <DeniedIcon />}
      </OrderStatusIconWrapper>
      <OrderStatusTitle>{title}</OrderStatusTitle>
      {text.map((t, index) => (
        <OrderStatusText key={index}>{t}</OrderStatusText>
      ))}
      {/* {status === OrderStatusEnum.DENIED && (
        <OrderStatusButton>
          Підтримка <HelpIcon style={{ marginLeft: '22px' }} />
        </OrderStatusButton>
      )} */}
    </OrderStatusPaper>
  );
}

export default OrderStatus;
