import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getActionTypePrefix } from '../../app/utils';
import { ThunkApi } from '../../app/common-types';
import { Features } from '../types';
import { ProductData, ProductsAsyncActions } from './types';

export const getProductsByCategoryAsync = createAsyncThunk<
  ProductData[],
  string,
  ThunkApi
>(
  getActionTypePrefix<ProductsAsyncActions>(
    Features.products,
    ProductsAsyncActions.GET_PRODUCTS,
  ),
  async (id, { rejectWithValue, extra: { api, notification } }) => {
    try {
      if (!id) {
        throw new Error('ID is not provided!');
      }
      const categories = await api.products.getProductsByCategory(id);
      return categories;
    } catch (e) {
      const error = e as AxiosError;
      notification({
        type: 'error',
        message: error.response?.data.error || error.message,
      });
      return rejectWithValue(error.message);
    }
  },
);
